.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App h3 {
  margin-top: 0; /* Adjust the value as needed */
  color: #949494;
  font-weight: normal;
  padding-top: 0px; /* Or you can reduce this value if it's already set */
}
.App h2 {
  margin-bottom: 0.5em; /* Adjust the value as needed */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.quiz-container {
  max-width: 600px;
  margin: 10px auto; /* Centers the container */
  padding: 10px; /* Inner buffer */
  box-sizing: border-box; /* Include padding in the width */
}
.front-page-container {
  padding: 20px; /* Adjust the value as needed */
}
.links-container a {
  white-space: nowrap; /* Prevents the text inside the anchor tags from wrapping */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.options-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5px, 200fr)); /* Adjust '100px' to the desired min width */
  justify-content: center; /* This will center the grid in the container */
  gap: 1px; /* The gap between columns */
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 0.8rem; /* Adjust label font size if needed */
  text-align: center; /* Ensures the text is centered under the radio button */
  
}

/* Add this to only show labels for the first, fourth, and seventh options */
.option:not(:nth-child(1)):not(:nth-child(4)):not(:nth-child(7)) .label {
  display: none; /* Hides the label text for non-specified buttons */
}
/* Other styles... */

.final-results-container {
  background-color: white;
  color: black; /* Or any color you want for the text */
  padding: 200px;
}
@media screen and (max-width: 600px) { /* Adjust max-width as needed for targeting mobile screens */
  .final-results-container {
    width: 100%; /* Adjust as necessary */
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }

  .final-results-container canvas {
    max-width: 100%; /* Adjust canvas size to fit the container */
    height: auto; /* Keeps the aspect ratio of the canvas */
  }
}

.links-container a {
  color: #aaa; /* This is a light grey, adjust as needed */
  text-decoration: none; /* Removes underline from links */
  margin-bottom: 5px; /* Adjust the space between links as needed */
}

.links-container a:hover {
  text-decoration: underline; /* Adds underline on hover for better UX */
}
.back-button {
  background: none;
  border: none;
  color: lightgrey;
  padding: 0;
  cursor: pointer;
}

.back-button:hover {
  text-decoration: underline;
  color: grey;
}
