.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  justify-content: space-between; /* This will space out the radio buttons evenly */
  align-items: center;
  width: 300px; /* Fixed width for consistent spacing */
  margin: 0 auto; /* This will center the container if it's within a flex container */
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 400px; /* Fixed height to keep elements vertically aligned */
}

.label {
  font-size: 0.8rem;
  margin-top: 4px;
}
.question-container p {
  font-size: 1.5em; /* Adjust this value to suit your design */
}